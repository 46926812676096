import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
import classNames from 'classnames';
import parseISO from 'date-fns/parseISO';
import { Filter } from 'app/components/Table/tableInterfaces';
import { RootState } from 'app/store';
import Table from 'app/components/Table/Table';
import { getRewardStatus } from 'app/utils/helpers';
import { CampaignProperties } from '../../../../interfaces/campaignInterfaces';
import {
    getRewardsTableDataSortedPaged,
    getRewardsTableTotalRows,
} from 'pages/CampaignDetails/campaignDetailsSelectors';
import { CampaignDetailsTableEntry } from 'pages/CampaignDetails/interfaces/campaignDetails';
import { getRewards, resetRewardFormData } from '../../../../state/campaign/campaignSlice';
import {
    setRewardsTablePage,
    setRewardsTableSort,
    setRewardsRowsPerPage,
    setRewardsTableFilters,
    resetRewardsTableFilters,
} from '../../../../state/ui/campaignDetails/campaignDetailsPageSlice';
import { showNotification } from '../../../../state/ui/notifications/notificationsSlice';
import Button from 'components/Button/Button';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import Skeleton from 'app/components/Skeleton/Skeleton';
import Tooltip from 'components/Tooltip/Tooltip';
import { ReactComponent as DownloadIcon } from 'static/icons/download.svg';
import { ReactComponent as EditIcon } from 'static/icons/edit.svg';
import { ReactComponent as CopyIcon } from 'static/icons/duplicate.svg';
import { ReactComponent as RefreshIcon } from 'static/icons/refresh.svg';
import { MAX_DISPLAYED_COUNTRIES, rewardsTableHeaders } from '../../constants/campaignRewardsTableConstants';
import { REWARD_STATUSES, REWARDS_ROWS_NUMBERS } from 'app/constants/rewardConstants';
import { rewardTypes } from 'constants/rewardConstants';
import styles from './CampaignRewards.module.scss';

const getCountriesCol = (target_countries: string[]): string => {
    if (!target_countries) {
        return '';
    }
    const otherCountriesNumber = target_countries.length - MAX_DISPLAYED_COUNTRIES;
    const countriesString = target_countries.slice(0, MAX_DISPLAYED_COUNTRIES).join(', ');
    return otherCountriesNumber > 0 ? `${countriesString} and ${otherCountriesNumber} others` : countriesString;
};

const getAvailablePercentage = (winCount: number, winMaxCount: number) => {
    return Math.round((1 - winCount / winMaxCount) * 100);
};

export default function CampaignRewards(): React.ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const totalRewards = useSelector<RootState, any | null>((state) => state.campaign.campaign.rewards);
    const rewards = useSelector<RootState, CampaignDetailsTableEntry[]>(getRewardsTableDataSortedPaged);
    const partners = useSelector<RootState, any[]>((state) => (state.campaign.campaign || {}).partners);
    const isRewardsLoading = useSelector<RootState, boolean>((state) => state.campaign.isRewardsLoading);
    const campaignId = useSelector<RootState, string>((state) => state.campaign.campaign.campaign_id);
    const rewardsTableSort = useSelector<RootState, any>((state) => state.campaignDetailsPage.rewardsTableSort);
    const rewardsTableFilters = useSelector<RootState, { [key: string]: Filter }>(
        (state) => state.campaignDetailsPage.rewardsTableFilters
    );
    const campaignProperties = useSelector<RootState, CampaignProperties>((state) => state.campaign.campaignProperties);
    const selectedTimezone = campaignProperties && campaignProperties.view_timezone ? campaignProperties.view_timezone : '+00:00';
    const rowsPerPage = useSelector<RootState, number>((state) => state.campaignDetailsPage.rewardsRowsPerPage);
    const rewardsTablePage = useSelector<RootState, number>((state) => state.campaignDetailsPage.rewardsTablePage);
    const rewardsTableTotalRows = useSelector<RootState, number>(getRewardsTableTotalRows);


    const refreshTable = useCallback(() => {
        dispatch(getRewards(campaignId));
    }, [campaignId]);

    const clearFilters = () => {
        dispatch(resetRewardsTableFilters());
    };

    const editReward = (id: string) => history.push(`${history.location.pathname}/edit/${id}/editRewards`);
    const rows = rewards.map(
        ({
            status,
            start_at,
            end_at,
            reward_id,
            reward_name,
            partner_name,
            reward_value,
            reward_probability,
            win_count,
            win_count_today,
            win_max,
            target_variant,
            target_countries,
        }) => [
                <EditIcon className={styles.editIcon} onClick={() => editReward(reward_id)}></EditIcon>,
                <Tooltip
                    position="right"
                    tooltip={
                        start_at || end_at ? (
                            <div className={styles.dateTootip}>
                                {start_at &&
                                    <div>Starts: {moment(parseISO(start_at)).utcOffset(selectedTimezone).format('ddd DD/MMM/yyyy, HH:mm Z')}</div>}
                                {end_at &&
                                    <div>Ends: {moment(parseISO(end_at)).utcOffset(selectedTimezone).format('ddd DD/MMM/yyyy, HH:mm Z')}</div>}
                            </div>
                        ) : null
                    }>
                    <span
                        className={classNames({
                            [styles.rewardActive]: status === REWARD_STATUSES.ACTIVE,
                            [styles.rewardExpired]: status === REWARD_STATUSES.EXPIRED,
                            [styles.rewardPending]: status === REWARD_STATUSES.PENDING,
                            [styles.rewardPaused]: status === REWARD_STATUSES.PAUSED,
                            [styles.rewardDisabled]: status === REWARD_STATUSES.DISABLED,
                            [styles.rewardEmpty]: status === REWARD_STATUSES.EMPTY,
                        })}
                    >
                        {status}
                    </span>
                </Tooltip>,
                <span className='reward_name'>{reward_name}</span>,
                <span className='partner_name'>{partner_name}</span>,
                rewardTypes.find((rt) => rt.value === reward_value)?.label,
                reward_probability,
                win_count,
                win_count_today,
                <div className={styles.percentageColumn}>
                    <span className={styles.winMax}>{+win_max - +win_count}</span>
                    <Tooltip
                        position="right"
                        tooltip={!!win_max &&
                            <div className={styles.dateTootip}>
                                <div>Total: {+win_max}</div>
                            </div>
                        }>
                        <ProgressBar percentage={getAvailablePercentage(+win_count, +win_max)}></ProgressBar>
                    </Tooltip>
                </div>,
                target_variant,
                <span className='target_countries'>{getCountriesCol(target_countries)}</span>,
                <div className={styles.rewardIdCol}>
                    <span>{reward_id}</span>
                    <button
                        className={styles.copyButton}
                        onClick={() => {
                            navigator?.clipboard?.writeText(reward_id);
                            dispatch(showNotification('Reward ID Copied!'));
                        }}>
                        <CopyIcon className={styles.copyIcon} />
                    </button>
                </div>,
            ]
    );

    const downloadCsv = () => {
        const exportFormatData = totalRewards.map((item: any) => {
            return {
                'Status': getRewardStatus(item.start_at, item.end_at, item.daily_limit, item.win_count_today, item.is_enabled, item.win_max, item.win_count),
                'Reward name': item.reward_name,
                'Partner': (partners.find((p: any) => p.partner_id === item.partner_id) || {}).name,
                'Reward type': rewardTypes.find((rt) => rt.value === item.reward_value)?.label,
                'Win probability': +item.reward_probability,
                'Codes used': +item.win_count,
                'Used today': +item.win_count_today,
                'Codes available': +item.win_max - +item.win_count,
                'Variant': item.target_variant ? item.target_variant : '',
                'Territories': item.target_countries ? getCountriesCol(item.target_countries) : '',
                'Reward ID': item.reward_id,
                'Starts on': item.start_at ? moment(parseISO(item.start_at)).utcOffset(selectedTimezone).format('ddd DD/MMM/yyyy, HH:mm Z') : '',
                'Ends': item.end_at ? moment(parseISO(item.end_at)).utcOffset(selectedTimezone).format('ddd DD/MMM/yyyy, HH:mm Z') : '',
                'Daily limit': item.daily_limit ? item.daily_limit : ''
            }
        });

        const titleKeys = !!exportFormatData?.length && Object.keys(exportFormatData[0]);

        const refinedData = []
        refinedData.push(titleKeys)

        exportFormatData.forEach((item: any) => {
            refinedData.push(item)
        })

        let csvContent = ''
        refinedData.forEach(row => {
            csvContent += Object.values(row)?.map(item => `"${item}"`).join(',') + '\n'
        })

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
        const objUrl = URL.createObjectURL(blob)
        return objUrl
    }

    return (
        <div className={styles.root}>
            <div className={styles.controlsHolder}>
                <Button
                    download
                    to={downloadCsv()}
                    iconRight={<DownloadIcon height={16} />}
                    disabled={isRewardsLoading}
                >
                    Download CSV
                </Button>
                <Button onClick={clearFilters} disabled={isRewardsLoading}>
                    Clear Filters
                </Button>
                <Button onClick={refreshTable} iconRight={<RefreshIcon height={16} />}
                    disabled={isRewardsLoading}>
                    Refresh Table
                </Button>
            </div>

            <Skeleton isLoading={isRewardsLoading}>
                {!isRewardsLoading && (
                    <>
                        <Table
                            id={`${campaignId}-rewards`}
                            sort={rewardsTableSort}
                            filters={rewardsTableFilters}
                            page={rewardsTablePage}
                            onRowsNumberSelected={(value: number) => dispatch(setRewardsRowsPerPage(value))}
                            rowsPerPage={rowsPerPage}
                            rowsNumbers={REWARDS_ROWS_NUMBERS}
                            rows={rows}
                            totalRows={rewardsTableTotalRows}
                            headers={rewardsTableHeaders}
                            resizableFields={['reward_name', 'partner_name', 'target_countries']}
                            onPageSelected={(p: number) => dispatch(setRewardsTablePage(p))}
                            onSort={(field: any) => dispatch(setRewardsTableSort(field))}
                            onFilter={(filter) => dispatch(setRewardsTableFilters(filter))}
                        />

                        <div className={styles.roundButtonHolder}>
                            <button
                                className={styles.roundButton}
                                disabled={!partners.length}
                                onClick={() => {
                                    dispatch(resetRewardFormData());
                                    history.push(`/campaign/${campaignId}/createReward`);
                                }}>

                                <Tooltip
                                    position="left"
                                    tooltip={!partners.length
                                        ? 'Add at least 1 partner'
                                        : 'Add Campaign Reward'
                                    }
                                >
                                    <span>+</span>
                                </Tooltip>
                            </button>
                        </div>
                    </>
                )}
            </Skeleton>
        </div>
    );
}
