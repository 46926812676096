import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { RootState } from 'app/store';
import { Partner } from 'app/interfaces/partner';
import Skeleton from 'app/components/Skeleton/Skeleton';
import Table from 'app/components/Table/Table';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { ReactComponent as IconEdit } from '../../../../static/icons/edit.svg';
import { ReactComponent as IconClose } from '../../../../static/icons/cross-small.svg';
import {
    getPartners,
    clearPartnerError,
    sendPartnersData,
    setPartnerError,
} from '../../../../state/campaign/campaignSlice';
import {
    resetPartnersTableFilters, setPartnersRowsPerPage,
    setPartnersTableFilters, setPartnersTablePage, setPartnersTableSort
} from '../../../../state/ui/campaignDetails/campaignDetailsPageSlice';
import { getPartnersTableDataSortedPaged, getPartnersTableTotalRows } from '../../../CampaignDetails/campaignDetailsSelectors';
import { CampaignPartnersTableEntry } from '../../../CampaignDetails/interfaces/campaignPartners';
import styles from './CampaignPartners.module.scss';
import { partnerTableHeaders } from 'pages/CampaignDetails/constants/campaignPartnersTableConstants';
import { PARTNERS_ROWS_NUMBERS } from 'app/constants/partnersConstants';
import { Filter } from 'app/components/Table/tableInterfaces';
import { ReactComponent as RefreshIcon } from 'static/icons/refresh.svg';
import { nanoid } from '@reduxjs/toolkit';

interface Error {
    message?: string;
    field?: string;
}


export default function CampaignPartners(): React.ReactElement {
    const dispatch = useDispatch();

    const partners = useSelector<RootState, CampaignPartnersTableEntry[]>(getPartnersTableDataSortedPaged);
    const allPartners = useSelector<RootState, Partner[]>((state) => state.campaign.campaign.partners);
    const partnerErrors = useSelector<RootState, any[]>((state) => state.campaign.campaignPartners.partnerErrors);
    const partnerServerError = useSelector<RootState, any>((state) => state.campaign.campaignPartners.partnerServerError);
    const isPartnersLoading = useSelector<RootState, boolean>((state) => state.campaign.isPartnersLoading);
    const campaignId = useSelector<RootState, any>((state) => (state.campaign.campaign || {}).campaign_id);

    const partnersTableTotalRows = useSelector<RootState, number>(getPartnersTableTotalRows);
    const rowsPerPage = useSelector<RootState, number>((state) => state.campaignDetailsPage.partnersRowsPerPage);
    const partnersTablePage = useSelector<RootState, any>((state) => state.campaignDetailsPage.partnersTablePage);
    const partnersTableSort = useSelector<RootState, any>((state) => state.campaignDetailsPage.partnersTableSort);
    const partnersTableFilters = useSelector<RootState, { [key: string]: Filter }>((state) => state.campaignDetailsPage.partnersTableFilters);

    const [addNewActionActive, setAddNewActionActive] = useState(false);
    const [enabledEditMode, setEnabledEditMode] = useState(-1);
    const [warning, setWarning] = useState<string>('');
    const [currentPartnerId, setCurrentPartnerId] = useState<string | undefined>('');
    
    const [partnersList, setPartnersList] = useState<CampaignPartnersTableEntry[]>([]);
    const [partnerName, setPartnerName] = useState<string[] | undefined>([])
    const [partnerEmail, setPartnerEmail] = useState<string[] | undefined>([])

    const findNewPartner = partnersList?.find((item: any) => !!item.isNew);
    const findCurrentPartner = allPartners?.find((item: any) => item.partner_id === currentPartnerId);
    const findSamePartnerName = findNewPartner && enabledEditMode === 0
        ? allPartners?.find((item: any) => (!item.isNew && item.name === partnerName?.[enabledEditMode]))
        : allPartners?.find((item: any) => (item.partner_id !== currentPartnerId && item.name === partnerName?.[enabledEditMode]))
        
    useEffect(() => {
        if (findSamePartnerName) {
            setWarning('Partner with this name already exists')
        } else {
            setWarning('')
        }
    }, [findSamePartnerName])

    useEffect(() => {
        if (findNewPartner) {
            setEnabledEditMode(0);
        } else {
            setEnabledEditMode(-1);
        }
    }, [partnersTablePage, findNewPartner])

    useEffect(() => {
        setPartnersList(partners)
        if (!findCurrentPartner) {
            setAddNewActionActive(false)
        }
    }, [partners, findCurrentPartner])

    useEffect(() => {
        setPartnerName(partnersList?.map(item => item.name));
        setPartnerEmail(partnersList?.map(item => item.support_email));
    }, [partnersList])

    const submitPartnersData = (partner: Partner) => {
        let err: Error | null = null;
        if (!partner.support_email) {
            err = { message: 'Email cannot be empty', field: 'support_email' };
        }
        if (!partner.name) {
            err = { message: 'Name cannot be empty', field: 'name' };
        }
        if (err) {
            dispatch(setPartnerError({ ...partnerErrors, [partner.partner_id]: err }));
            return;
        } else {
            dispatch(clearPartnerError(partner.partner_id));
            setEnabledEditMode(-1);
        }

        dispatch(sendPartnersData(partner));
        setAddNewActionActive(false)
    };

    const handleNameChange = (value: any, partner_id: string) => {
        setPartnerName(partnersList.map(item => item.partner_id === partner_id ? value : item.name))
    }
    const handleEmailChange = (value: any, partner_id: string) => {
        setPartnerEmail(partnersList.map(item => item.partner_id === partner_id ? value : item.support_email))
    }

    const rows = partnersList?.map(({
        partner_id,
        name,
        support_email,
        isNew
    }, index) => [
            <div className={styles.actionCell}>
                {enabledEditMode === index ? (
                    <IconClose
                        className={styles.iconEdit}
                        onClick={() => {
                            setEnabledEditMode(-1);
                            setCurrentPartnerId('');
                            setAddNewActionActive(false);
                            dispatch(getPartners(campaignId));
                        }}
                    />
                ) : (
                    <IconEdit
                        className={styles.iconEdit}
                        onClick={() => {
                            setEnabledEditMode(index)
                            setCurrentPartnerId(partner_id)
                            setAddNewActionActive(true);
                        }}
                    />
                )}
            </div>,
            <>
                {enabledEditMode === index || findNewPartner ? (
                    <div className={styles.inputBlock}>
                        <Input
                            disableErrorLabel={true}
                            error={
                                partnerErrors[partner_id] && partnerErrors[partner_id].field === 'name'
                                    ? partnerErrors[partner_id].message
                                    : null
                            }
                            type="text"
                            value={partnerName?.[index]}
                            disabled={enabledEditMode !== index}
                            onChange={(e) => handleNameChange(e.target.value, partner_id)}
                        />
                        {warning && enabledEditMode === index &&
                            <span className={styles.warning}>{warning}</span>
                        }
                    </div>
                ) : (
                    <span className='name'>{name}</span>
                )}
            </>,
            <>
                {enabledEditMode === index || findNewPartner ? (
                    <div className={classNames(styles.inputBlock, {
                        [styles.inputWarningBlock]: !!warning && enabledEditMode === index,
                    })}>
                        <Input
                            disableErrorLabel={true}
                            error={
                                partnerErrors[partner_id] && partnerErrors[partner_id].field === 'support_email'
                                    ? partnerErrors[partner_id].message
                                    : null
                            }
                            type="email"
                            autoComplete="off"
                            value={partnerEmail?.[index]}
                            disabled={enabledEditMode !== index}
                            onChange={(e) => handleEmailChange(e.target.value, partner_id)}
                        />
                    </div>
                ) : (
                    <span className='support_email'>{support_email}</span>
                )}
            </>,
            <div className={classNames({
                [styles.submitWarning]: !!warning && enabledEditMode === index,
            })}>
                <Button
                    type="action"
                    className={styles.submitButton}
                    disabled={enabledEditMode !== index}
                    onClick={() => submitPartnersData({
                        support_email: partnerEmail?.[index],
                        partner_id,
                        name: partnerName?.[index],
                        isNew
                    })}
                >
                    {isNew ? 'Add new' : 'Update'}
                </Button>
            </div>
        ]
    );

    const handleScroll = () => {
        const element = document.getElementById('partners_table');
        if (element) {
            element.scrollIntoView();
        }
    };

    const refreshTable = useCallback(() => {
        dispatch(getPartners(campaignId));
    }, [campaignId]);

    const clearFilters = () => {
        dispatch(resetPartnersTableFilters());
    };

    const handleSort = (field: any) => {
        if (!addNewActionActive) {
            setEnabledEditMode(-1);
            dispatch(setPartnersTableSort(field))
        }
    }

    const handleAddNew = () => {
        handleScroll();
        setAddNewActionActive(true);
        setPartnersList([{ name: '', support_email: '', partner_id: nanoid(), isNew: true }, ...partnersList])
    }

    return (
        <div className={styles.root}>
            <div className={styles.controlsHolder}>
                <Button onClick={clearFilters} disabled={isPartnersLoading}>
                    Clear Filters
                </Button>
                <Button onClick={refreshTable} iconRight={<RefreshIcon height={16} />}
                    disabled={isPartnersLoading}>
                    Refresh Table
                </Button>
            </div>
            <Skeleton isLoading={isPartnersLoading}>
                {!isPartnersLoading && (
                    <>
                        <div className={styles.partnersList} id="partners_table">
                            <Table
                                id={`${campaignId}-partners`}
                                sort={partnersTableSort}
                                filters={partnersTableFilters}
                                page={partnersTablePage}
                                onRowsNumberSelected={(value: number) => dispatch(setPartnersRowsPerPage(value))}
                                rowsPerPage={rowsPerPage}
                                rowsNumbers={PARTNERS_ROWS_NUMBERS}
                                rows={rows}
                                totalRows={partnersTableTotalRows}
                                headers={partnerTableHeaders}
                                resizableFields={['name', 'support_email']}
                                onPageSelected={(p: number) => dispatch(setPartnersTablePage(p))}
                                onSort={(field: any) => handleSort(field)}
                                onFilter={(filter) => dispatch(setPartnersTableFilters(filter))}
                                disabled={addNewActionActive}
                            />
                            <div className={styles.roundButtonHolder}>
                                <button
                                    disabled={addNewActionActive}
                                    className={styles.roundButton}
                                    onClick={handleAddNew}
                                >
                                    <Tooltip position="left" tooltip="Add Campaign Partner">
                                        <span>+</span>
                                    </Tooltip>
                                </button>
                            </div>
                        </div>
                        {partnerServerError && <p className={styles.formError}>{partnerServerError}</p>}
                    </>
                )}
            </Skeleton>
        </div>
    );
}
