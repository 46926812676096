import Table from 'app/components/Table/Table';
import {
  getMetricsTableDataSortedPaged,
  getMetricsTableTotalRows,
} from 'pages/CampaignDetails/campaignDetailsSelectors';
import {
  setMetricsTableFilters,
  setMetricsTablePage,
  setMetricsTableSort,
  setMetricsRowsPerPage,
  resetMetricsTableFilters,
} from 'state/ui/campaignDetails/campaignDetailsPageSlice';
import { campaignMetricsTableHeaders } from '../../constants/campaignMetricsTableConstants';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { CampaignMetricsTableEntry } from 'pages/CampaignDetails/interfaces/campaignMetrics';
import format from 'date-fns/format';
import Skeleton from 'app/components/Skeleton/Skeleton';
import styles from './CampaignMetricsTable.module.scss';
import Button from 'components/Button/Button';
import { ReactComponent as RefreshIcon } from 'static/icons/refresh.svg';
import { ReactComponent as DownloadIcon } from 'static/icons/download.svg';
import { getMetrics, downloadMetricsCsvFunction } from 'state/campaign/campaignSlice';
import CampaignMetricsDateFilter from '../CampaignMetricsDateFilter/CampaignMetricsDateFilter';
import { DATE_TIME_FORMAT } from 'app/constants/dateConstants';
import { METRICS_ROWS_NUMBERS } from 'app/constants/metricsConstants';

export default function CampaignMetricsTable(): React.ReactElement {
  const dispatch = useDispatch();

  const metrics = useSelector(getMetricsTableDataSortedPaged);
  const isMetricsLoading = useSelector<RootState, boolean>((state) => state.campaign.isMetricsLoading);
  const campaignId = useSelector<RootState, string>((state) => state.campaign.campaign.campaign_id);
  const metricsTableSort = useSelector<RootState, any>((state) => state.campaignDetailsPage.metricsTableSort);
  const metricsTableFilters = useSelector<RootState, any>((state) => state.campaignDetailsPage.metricsTableFilters);
  const metricsTablePage = useSelector<RootState, any>((state) => state.campaignDetailsPage.metricsTablePage);
  const metricsTableTotalRows = useSelector<RootState, any>(getMetricsTableTotalRows);
  const metricsFilters = useSelector<RootState, any>((state) => state.campaign.metricsFilters);
  const rowsPerPage = useSelector<RootState, number>((state) => state.campaignDetailsPage.metricsRowsPerPage);

  useEffect(() => {
    dispatch(setMetricsTablePage(0));
    dispatch(getMetrics(campaignId));
  }, [metricsFilters]);

  const refreshTable = useCallback(() => {
    dispatch(setMetricsTablePage(0));
    dispatch(getMetrics(campaignId));
  }, [campaignId]);

  const clearFilters = () => {
    dispatch(resetMetricsTableFilters());
  };

  const downloadCsv = useCallback(() => {
    dispatch(downloadMetricsCsvFunction(campaignId));
  }, [campaignId]);

  const rows = metrics.map(
    ({ created_at, click_id, code, event_type, country, gender, age, variant, rewards }: CampaignMetricsTableEntry) => [
      format(created_at, DATE_TIME_FORMAT),
      click_id,
      code,
      event_type,
      country,
      gender,
      age,
      variant,
      rewards,
    ]
  );

  return (
    <div className={styles.root}>
      <div className={styles.tableControls}>
        <CampaignMetricsDateFilter />
        <Button onClick={downloadCsv} iconRight={<DownloadIcon height={16} />} disabled={isMetricsLoading}>
          Download CSV
        </Button>
        <Button onClick={clearFilters} disabled={isMetricsLoading}>
          Clear Filters
        </Button>
        <Button onClick={refreshTable} iconRight={<RefreshIcon height={16} />} disabled={isMetricsLoading}>
          Refresh Table
        </Button>
      </div>
      <Skeleton isLoading={isMetricsLoading}>
        {!isMetricsLoading && (
          <Table
            id={`${campaignId}-metrics`}
            sort={metricsTableSort}
            filters={metricsTableFilters}
            page={metricsTablePage}
            rowsPerPage={rowsPerPage}
            onRowsNumberSelected={(value: number) => dispatch(setMetricsRowsPerPage(value))}
            rows={rows}
            rowsNumbers={METRICS_ROWS_NUMBERS}
            totalRows={metricsTableTotalRows}
            headers={campaignMetricsTableHeaders}
            onPageSelected={(p: number) => dispatch(setMetricsTablePage(p))}
            onSort={(field: string) => dispatch(setMetricsTableSort(field))}
            onFilter={(filter) => dispatch(setMetricsTableFilters(filter))}
          />
        )}
      </Skeleton>
    </div>
  );
}
